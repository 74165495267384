import React, { useState } from "react";
import Global from "../../APIConfig";
import AuthUser from "../../AuthUser";
import { useNavigate,Link } from "react-router-dom";

function LeftPanel() {
  let navigate = useNavigate();
  const{logout}=AuthUser();
  const [show, SetShow] = useState('close');
  const items = JSON.parse(localStorage.getItem('user'));
  const userlogout=()=>{
    logout();
    navigate('/');
    window.location.reload();
}
  return (
        <div className={`sidebar ${show}`}>
          <div className="logo-details">
            <div className="head_icon" onClick={()=>{SetShow(show=='close'?'show':'close')}}>
                <i className="bx bx-menu"></i>
            </div>
            <span className="logo_name">Sky Ship</span>
          </div>
          <ul className="nav-links">
            <li>
              <div className="iocn-link">
                <a href="#">
                  <i className="bx bx-grid-alt"></i>
                  <span className="link_name">Dashboard</span>
                </a>
                <i className="bx bxs-chevron-down arrow"></i>
              </div>
              <ul className="sub-menu">
                <li><a className="link_name" href="/Customer-Dash">Dashboard</a></li>
                <li><a href="/Customer-Dash">Dashboard</a></li>
              </ul>
            </li>
            <li>
              <div className="iocn-link">
                <a href="#">
                  <i className="bx bx-package"></i>
                  <span className="link_name">Shipments</span>
                </a>
                <i className="bx bxs-chevron-down arrow"></i>
              </div>
              <ul className="sub-menu">
                <li><a className="link_name" href="#">Shipments</a></li>
                <li><a href="/Shipmentlist/All">All Shipments</a></li>
                <li><a href="/Shipmentlist/B">Undelivered Shipments</a></li>
                <li><a href="/Shipmentlist/D">Delivered Shipments</a></li>
                {/* <li><a href="shipments.php?status=ndr">Missing Shipments</a></li> */}
                {/* <li><a href="shipments.php?status=ndr">NDR Shipments</a></li> */}
                {/* <li><a href="/Shipmentlist/R">RTO Shipments</a></li> */}
              </ul>
            </li>
            
            <li>
              <div className="iocn-link">
                <a href="#">
                  <i className="bx bx-time"></i>
                  <span className="link_name">Scheduled PRS </span>
                </a>
                <i className="bx bxs-chevron-down arrow"></i>
              </div>
              <ul className="sub-menu ">
                <li><a className="link_name" href="#">Scheduled Pickup Management</a></li>
                <li><a className="" href="prs_running.php">All Scheduled Pickups</a></li>
                <li><a className="" href=".php">Scheduled PRS Customer</a></li>
              </ul>
            </li>
            <li>
              <div className="iocn-link">
                <a href="#">
                  <i className="bx bx-cuboid"></i>
                  <span className="link_name">Account Transaction</span>
                </a>
                <i className="bx bxs-chevron-down arrow"></i>
              </div>
              <ul className="sub-menu">
                <li><a className="link_name" href="#">Account Transaction</a></li>
                <li><a className="" href="/CustomerWallet">My Wallet</a></li>
                <li><a href="/CustomerCredit">My Credit Score</a></li>
              </ul>
            </li>
            <li>
              <div className="iocn-link">
                <a href="#">
                  <i className="bx bx-cube-alt"></i>
                  <span className="link_name">Bag Manifest</span>
                </a>
                <i className="bx bxs-chevron-down arrow"></i>
              </div>
              <ul className="sub-menu">
                <li><a className="link_name" href="#">Warehouse Management</a></li>
                <li><a href="/WarehouseAdd">Warehouse Add</a></li>
                <li><a href="/WarehouseList">Warehouse List</a></li>
              </ul>
            </li>
            <li>
              <div className="iocn-link">
                <a href="#">
                  <i className="bx bx-chip"></i>
                  <span className="link_name">Developer</span>
                </a>
                <i className="bx bxs-chevron-down arrow"></i>
              </div>
              <ul className="sub-menu">
                <li><a className="link_name" href="#">Developer Management</a></li>
                <li><a href="/Developerkey">Developer Key</a></li>
              </ul>
            </li>
            <li>
              <div className="iocn-link">
                <a href="#">
                  <i className="bx bx-user"></i>
                  <span className="link_name">Profile</span>
                </a>
                <i className="bx bxs-chevron-down arrow"></i>
              </div>
              <ul className="sub-menu">
                <li><a className="link_name" href="#">Profile</a></li>
                <li><a className="" href="/UpdateProfile">Update Profile</a></li>
                <li><a href="/UpdatePassword">Update Password</a></li>
              </ul>
            </li>
            <li>
            <div className="profile-details">
                <div className="profile-content">
                <img src={`${Global.logoUrl}${items.customer_image}`} alt="profileImg" />
                </div>
                <div className="name-job">
                <div className="profile_name">{items.name}</div>
                <div className="job">{items.company}</div>
                </div>
                <i className="bx bx-log-out" onClick={()=>userlogout()}></i>
            </div>
        </li>
      </ul >
    </div >
    );

}
export default LeftPanel;