import React, {useState, useEffect}  from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/LeftPanel";
import axios from "axios";
import Global from "../../APIConfig";
import { useNavigate,useParams,Link } from "react-router-dom";
import AuthUser from "../../AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notfound from '../../notFound.png';

function ShipmentDetails(){
    let navigate = useNavigate();
    const { slip_no } =useParams();
    const [shipmentdetails, setShipmentdetails] = useState([]);
    const [status, setStatus] = useState([]);
    const{headers1,headers2,logout}=AuthUser();
    useEffect(()=>{
        GetPickupDetails();
    },[]);

    const GetPickupDetails=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Customershipmentdetailsbyslipno',
            headers:headers1,
            data: {
                slip_no: slip_no
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var shipmentdata=response.data.value;
                var statusdata = response.data.value.shipmentallstatus;
                setShipmentdetails(shipmentdata);
                setStatus(statusdata);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }

    const ShipmentDelete=(id)=>{
        if (window.confirm('Do You  want to delete ?')) {
            axios({
                method: 'post',
                url: Global.baseUrl+'CustomerShipmentDelete',
                headers:headers1,
                data: {
                    slip_no:id
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                   toast.success(response.data.message);
                   navigate('/Shipmentlist/All');
                }
                else
                {
                    toast.warn(response.data.message);
                }
              })
              .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
          } else {
          }
        
    }

    return(
        <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Shipments</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">AWB #{slip_no} </h2>
                        </div>
                    </div>
                </div>
                
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="data_table">
                        <span dangerouslySetInnerHTML={{__html:shipmentdetails.shipmentstatus}}></span>
                    </div>
                    
                    
                    <div className="btn-group ms-2 ms-lg-3">
                        <Link to={`/ShipmentLabel?slipno=${slip_no}`} className="btn btn-sm btn-outline-gray-700" data-bs-toggle="tooltip" title="Print Label"><i className="bx bx-barcode"></i></Link>
                        <Link to={`/ShipmentPrintReceipt/${slip_no}`} className="btn btn-sm btn-outline-gray-700" data-bs-toggle="tooltip" title="Print Receipt"><i className="bx bx-printer"></i> </Link>
                        <Link to={`/ShipmentInvoice/${slip_no}`} className="btn btn-sm btn-outline-gray-700"  data-bs-toggle="tooltip" title="Print Invoice"><i className="bx bx-file"></i> </Link>
                        <button type="button" className="btn btn-sm btn-outline-gray-700"  data-bs-toggle="tooltip" title="Delete" onClick={()=>ShipmentDelete(slip_no)}><i className="bx bx-x"></i> </button>
                        <Link to={`/ShipmentUpdateStatus/${slip_no}`} className="btn btn-sm btn-outline-gray-700"  data-bs-toggle="tooltip" title="Update Status"><i className="bx bx-revision"></i> </Link>
                        <Link to={`/ShipmentEdit/${slip_no}`} className="btn btn-sm btn-outline-gray-700" data-bs-toggle="tooltip" title="Update Status"><i className="bx bx-edit"></i></Link>
                        {shipmentdetails.shipment_type =='International'&&
                        <button type="button" className="btn btn-sm btn-outline-gray-700"  data-bs-toggle="tooltip" title="Forwared Through"><i className="bx bx-move-horizontal"></i> </button>
                        }
                    </div>
                </div>
            </div>

            <div className="row shipment_detail_sho">
                <div className="col-12 col-md ">
                    <div className="row">
                        <div className="col-12 col-md-12 mb-3">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-1">
                                            <div className="icon_sm">
                                                <i className="bx bx-package"></i>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md">
                                            <h3>Shipment Information</h3>
                                        </div>
                                        <div className="col-12 col-md-3 text-end">
                                            <small className="fs-12">Created By: {shipmentdetails.name}</small>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Created Date</small>
                                            <p><strong>{shipmentdetails.entrydate}</strong></p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Shipment No.</small>
                                            <p><strong><a href="">{shipmentdetails.slip_no}</a></strong></p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Order No.</small>
                                            <p><strong>-</strong></p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Order Type</small>
                                            <p><strong>{shipmentdetails.nrd}</strong></p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Shipment Type</small>
                                            <p><strong>{shipmentdetails.shipment_type}</strong></p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Services</small>
                                            <p><strong>{shipmentdetails.services_name}</strong></p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Bill To:</small>
                                            <p>
                                            {shipmentdetails.bill_to =='S'&&
                                                <strong>Sender</strong>
                                            }
                                            {shipmentdetails.bill_to =='R'&&
                                                <strong>Receiver</strong>
                                            }
                                            {shipmentdetails.bill_to =='O'&&
                                                <strong>Other</strong>
                                            }</p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Type</small>
                                            <p>
                                                {shipmentdetails.booking_mode =='Prepaid'?
                                                <strong>{shipmentdetails.booking_mode}</strong>
                                                :
                                                <strong>{shipmentdetails.booking_mode} ${shipmentdetails.total_cod_amt}</strong>
                                                }
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Insured</small>
                                            <p>
                                                {shipmentdetails.insurence_charge !='0.00'?
                                                <strong>Yes</strong>
                                                :
                                                <strong>NO</strong> 
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-12 mb-3 fs-normal-o">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-1">
                                            <div className="icon_sm">
                                                <i className="bx bx-checkbox-square"></i>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md">
                                            <h3>Shipment Details</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <h6>Ship From</h6>
                                            <div className="row">
                                                <div className="col-12">
                                                    <small className="text-muted">Sender Name:</small>
                                                    <p><strong>{shipmentdetails.sender_name}</strong></p>
                                                </div>
                                                <div className="col-12 ">
                                                    <small className="text-muted">Sender Address:</small>
                                                    <p><strong>{shipmentdetails.sender_address}, {shipmentdetails.sendercity}, {shipmentdetails.sendercountry} {shipmentdetails.sender_zip}</strong></p>
                                                </div>
                                                <div className="col-12">
                                                    <small className="text-muted">Sender City:</small>
                                                    <p><strong>{shipmentdetails.sendercity}</strong></p>
                                                </div>
                                                <div className="col-12">
                                                    <small className="text-muted">Sender State:</small>
                                                    <p><strong>{shipmentdetails.senderstate}</strong></p>
                                                </div>
                                                <div className="col-12">
                                                    <small className="text-muted">Sender Country:</small>
                                                    <p><strong>{shipmentdetails.sendercountry}</strong></p>
                                                </div>
                                                <div className="col-12">
                                                    <small className="text-muted">Sender Email:</small>
                                                    <p><strong>{shipmentdetails.sender_email}</strong></p>
                                                </div>
                                                <div className="col-12  ">
                                                    <small className="text-muted">Sender Mobile:</small>
                                                    <p><strong>{shipmentdetails.sender_phone}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <h6>Ship To</h6>
                                            <div className="row">
                                                <div className="col-12">
                                                    <small className="text-muted">Receiver Name:</small>
                                                    <p><strong>{shipmentdetails.reciever_name}</strong></p>
                                                </div>
                                                <div className="col-12 ">
                                                    <small className="text-muted">Receiver Address:</small>
                                                    <p><strong>{shipmentdetails.reciever_address}, {shipmentdetails.receivercity}, {shipmentdetails.receivercountry} {shipmentdetails.reciever_zip}</strong></p>
                                                </div>
                                                <div className="col-12">
                                                    <small className="text-muted">Receiver City:</small>
                                                    <p><strong>{shipmentdetails.receivercity}</strong></p>
                                                </div>
                                                <div className="col-12">
                                                    <small className="text-muted">Receiver State:</small>
                                                    <p><strong>{shipmentdetails.receiverstate}</strong></p>
                                                </div>
                                                <div className="col-12">
                                                    <small className="text-muted">Receiver Country:</small>
                                                    <p><strong>{shipmentdetails.receivercountry}</strong></p>
                                                </div>
                                                <div className="col-12">
                                                    <small className="text-muted">Receiver Email:</small>
                                                    <p><strong>{shipmentdetails.reciever_email}</strong></p>
                                                </div>
                                                <div className="col-12  ">
                                                    <small className="text-muted">Receiver Mobile:</small>
                                                    <p><strong>{shipmentdetails.reciever_phone}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <h6>Bill To</h6>
                                            {shipmentdetails.bill_to =='S'?
                                                <div className="row">
                                                <div className="col-12">
                                                    <small className="text-muted">Sender Name:</small>
                                                    <p><strong>{shipmentdetails.sender_name}</strong></p>
                                                </div>
                                                <div className="col-12 ">
                                                    <small className="text-muted">Sender Address:</small>
                                                    <p><strong>{shipmentdetails.sender_address}, {shipmentdetails.sendercity}, {shipmentdetails.sendercountry} {shipmentdetails.sender_zip}</strong></p>
                                                </div>
                                                <div className="col-12">
                                                    <small className="text-muted">Sender City:</small>
                                                    <p><strong>{shipmentdetails.sendercity}</strong></p>
                                                </div>
                                                <div className="col-12">
                                                    <small className="text-muted">Sender State:</small>
                                                    <p><strong>{shipmentdetails.senderstate}</strong></p>
                                                </div>
                                                <div className="col-12">
                                                    <small className="text-muted">Sender Country:</small>
                                                    <p><strong>{shipmentdetails.sendercountry}</strong></p>
                                                </div>
                                                <div className="col-12">
                                                    <small className="text-muted">Sender Email:</small>
                                                    <p><strong>{shipmentdetails.sender_email}</strong></p>
                                                </div>
                                                <div className="col-12  ">
                                                    <small className="text-muted">Sender Mobile:</small>
                                                    <p><strong>{shipmentdetails.sender_phone}</strong></p>
                                                </div>
                                            </div>
                                            :
                                            <div className="row">
                                            <div className="col-12">
                                                <small className="text-muted">Receiver Name:</small>
                                                <p><strong>{shipmentdetails.reciever_name}</strong></p>
                                            </div>
                                            <div className="col-12 ">
                                                <small className="text-muted">Receiver Address:</small>
                                                <p><strong>{shipmentdetails.reciever_address}, {shipmentdetails.receivercity}, {shipmentdetails.receivercountry} {shipmentdetails.reciever_zip}</strong></p>
                                            </div>
                                            <div className="col-12">
                                                <small className="text-muted">Receiver City:</small>
                                                <p><strong>{shipmentdetails.receivercity}</strong></p>
                                            </div>
                                            <div className="col-12">
                                                <small className="text-muted">Receiver State:</small>
                                                <p><strong>{shipmentdetails.receiverstate}</strong></p>
                                            </div>
                                            <div className="col-12">
                                                <small className="text-muted">Receiver Country:</small>
                                                <p><strong>{shipmentdetails.receivercountry}</strong></p>
                                            </div>
                                            <div className="col-12">
                                                <small className="text-muted">Receiver Email:</small>
                                                <p><strong>{shipmentdetails.reciever_email}</strong></p>
                                            </div>
                                            <div className="col-12  ">
                                                <small className="text-muted">Receiver Mobile:</small>
                                                <p><strong>{shipmentdetails.reciever_phone}</strong></p>
                                            </div>
                                        </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 mb-3">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-1">
                                            <div className="icon_sm">
                                                <i className="bx bx-box"></i>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md">
                                            <h3>Package Details</h3>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Applicable Weight (in Kg)</small>
                                            <p><strong>{shipmentdetails.volumetric_weight}KG</strong></p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Dead Weight (in Kg)</small>
                                            <p><strong>{shipmentdetails.weight}KG</strong></p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Volumetric Weight (in kg)</small>
                                            <p><strong>{shipmentdetails.volumetric_weight}KG </strong></p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Shipment value</small>
                                            <p><strong>${shipmentdetails.total_amt}</strong></p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Number of Boxes</small>
                                            <p><strong>-</strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 mb-3">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-1">
                                            <div className="icon_sm">
                                                <i className="bx bx-box"></i>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md">
                                            <h3>Box Details</h3>
                                        </div>
                                    </div>
                                    <table className="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th>Product Name</th>
                                            <th>Dimensions (cm)</th>
                                            <th>Weight (KG)</th>
                                            <th>Price</th>
                                            <th>QTY</th>
                                            <th>Total</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                          </tr>
                                          
                                          <tr>
                                            <td colspan="5" className="text-end">
                                                <strong>Total</strong>
                                            </td>
                                            <td>-</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 mb-3">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-1">
                                            <div className="icon_sm">
                                                <i className="bx bx-money-withdraw"></i>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md">
                                            <h3>Payment Details</h3>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Transaction ID</small>
                                            <p><strong>-</strong></p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Payment Mode</small>
                                            <p><strong>{shipmentdetails.customer_booking_mode}</strong></p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Total Paid Amount</small>
                                            <p><strong>${shipmentdetails.total_amt}</strong></p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Bill To (Customer)</small>
                                            <p>
                                                {shipmentdetails.bill_to =='S'?
                                                <strong><a href="">{shipmentdetails.sender_company_name}</a></strong>
                                                :
                                                <strong><a href="">{shipmentdetails.receiver_company_name}</a></strong>
                                                }
                                                </p>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <small className="text-muted">Customer Account No.</small>
                                            <p><strong>{shipmentdetails.Customer_Account_No}</strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 position-relative">
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="row mb-3">
                            <div className="col-12 col-md-2">
                                <div className="icon_sm">
                                    <i className="bx bx-package"></i>
                                </div>
                            </div>
                            <div className="col-12 col-md">
                                <h3>Shipment Info</h3>
                            </div>
                        </div>
                        <small className="text-muted">Origin</small>
                        <p><strong>{shipmentdetails.sendercity}, {shipmentdetails.senderstate}, {shipmentdetails.sendercountry} - {shipmentdetails.sender_zip}</strong></p>
                        <small className="text-muted">Destination</small>
                        <p><strong>{shipmentdetails.receivercity}, {shipmentdetails.receiverstate}, {shipmentdetails.receivercountry} - {shipmentdetails.reciever_zip}</strong></p>

                        <small className="text-muted">Last Location</small>
                        <p><strong>{shipmentdetails.shipmentlastlocation}</strong></p>

                        <small className="text-muted">Expected Schedule Delivery</small>
                        <p><strong>{shipmentdetails.expected_date} by EOD</strong></p>
                      </div>
                    </div>
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="row mb-3">
                            <div className="col-12 col-md-2">
                                <div className="icon_sm">
                                    <i className="bx bx-package"></i>
                                </div>
                            </div>
                            <div className="col-12 col-md">
                                <h3>Shipment POD</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md">
                                <h6>Receiver's Signature</h6>
                                {shipmentdetails.signature_img != undefined && shipmentdetails.signature_img != "" ?
                                    <img src={`${Global.logoUrl}${shipmentdetails.signature_img}`} className="img-fluid" />
                                :
                                    <img src={notfound} className="img-fluid" />
                                }
                            </div>
                            <div className="col-12 col-md">
                                <h6>Receiver's Image</h6>
                                {shipmentdetails.receiver_image != undefined && shipmentdetails.receiver_image != "" ?
                                    <img src={`${Global.logoUrl}${shipmentdetails.receiver_image}`} className="img-fluid" />
                                :
                                    <img src={notfound} className="img-fluid" />
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md">
                            <small className="text-muted">Receiver Name</small>
                            <p><strong>{shipmentdetails.delevered_to}</strong></p>
                            </div>
                            <div className="col-12 col-md">
                                <small className="text-muted">Receiver Mobile</small>
                                <p><strong>{shipmentdetails.delevered_no}</strong></p>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mb-3 sticky-top">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-12 col-md-2">
                                    <div className="icon_sm">
                                        <i className="bx bx-search"></i>
                                    </div>
                                </div>
                                <div className="col-12 col-md">
                                    <h3>Shipment Tracking</h3>
                                </div>
                            </div>
                            
                            <div className="timeline-wrapper"> 
                                <ul className="StepProgress">
                                    {status.map((st,i)=>{
                                        return(
                                    <li className="StepProgress-item is-done" key={i}>
                                        <div className="bold time">
                                            <h4>{st.pickup_time}</h4>
                                            <h6>{st.pickup_time}</h6>
                                            <h5>{st.city}</h5>
                                        </div> 
                                        <div className="bold">{st.Details}
                                            <p>{st.Activites}</p>
                                            <small>Updated By: {st.name}     </small>
                                        </div>
                                    </li>
                                    );
                                })}
                                </ul>
                            </div>
                        </div>
                      </div>
                </div>
            </div>
            <Footer/>
        </main>
        </>

    );
}
export default ShipmentDetails;