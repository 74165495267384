import { useState, useEffect } from 'react';
import Header from './include/header';
import Footer from './include/footer';
import LeftPanel from './include/LeftPanel';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Global from '../APIConfig';
import  axios from "axios";
import AuthUser from "../AuthUser";
import CopyToClipboard from 'react-copy-to-clipboard';

function CustomerDashboard() {
    let navigate = useNavigate();
    const{headers1,logout}=AuthUser();
const [trackingnumber, setTrackingnumber] = useState('');
const [customerDetail, setCustomerDetail] = useState('');
const [totalshipment, setTotalshipment] = useState('');
const [totalDeliveredshipment, setTotalDeliveredshipment] = useState('');
const items = JSON.parse(localStorage.getItem('user'));
    useEffect(() => {
        GetCustomerDetail();
        Get30DaysShipments();
        Get30DaysDeliveredShipments();
      }, []);
      const GetCustomerDetail = () =>{
        axios({
            method: 'post',
            url: Global.baseUrl+'getFrontCutomerDetail',
            headers:headers1,
            data: {
                UniqueId:items.uniqueid
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data;
                setCustomerDetail(data.value);
                
            }
            else
            {
                setCustomerDetail('');
                
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
      }
      const Trackingshipment=()=>{
        if(trackingnumber !="")
        {
            navigate(`/ShipmentTracking/${trackingnumber}`);
        }
        else
        {
            toast.warn("Please Enter AWB No.");
        }
      }
      const Get30DaysShipments=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Getcustomer30DaysShipments',
            headers:headers1,
            data: {
                UniqueId:items.uniqueid,
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data;
                setTotalshipment(data.label);
                
            }
            else
            {
                setTotalshipment(0);
                
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
        }

          const Get30DaysDeliveredShipments=()=>{
            axios({
                method: 'post',
                url: Global.baseUrl+'GetCustomer30DaysDeliveredShipment',
                headers:headers1,
                data: {
                    UniqueId:items.uniqueid,
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    var data=response.data;
                    setTotalDeliveredshipment(data.label);
                    
                }
                else
                {
                    setTotalDeliveredshipment(0);
                    
                }
              })
              .catch(function (error) {
                if(error.response.status==401){
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
            }

            const handlekey = ()=>{
                const userConfirmed = window.confirm("Do you want to proceed?");
                if(userConfirmed ==true)
                {
                    axios({
                        method: 'post',
                        url: Global.baseUrl+'generateKey',
                        headers:headers1,
                        data: {
                            UniqueId:items.uniqueid
                        }
                      })
                      .then(function (response) {
                        if(response.data.status == 200)
                        {
                            window.location.reload();
                        }
                        else
                        {
                            toast.warning(response.data.message);
                        }
                      })
                      .catch(function (error) {
                        if(error.response.status==401){
                            logout();
                            navigate('/');
                            window.location.reload();
                        }
                        // console.log(error);
                      });
                }
                else
                {
                    
                }
            }
      
  return (
    <>
        <LeftPanel/>
        <main className='home-section'>
        <Header/>
        <ToastContainer/>
        <div className="overlay"></div>
        <div className="py-4 new_request_menu">
            <div className='col-12 col-md-12'>
                <div className='row'>
                    <div className='col-12 col-md-10 col-sm-12'>
                    <div className="dropdown">
                        <button className="btn btn-gray-800 d-inline-flex align-items-center me-2 dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <svg className="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                            New Request
                        </button>
                        <div className="dropdown-menu p-0">
                            <div className="card">
                                <div className="card-body">
                                <h4>Add Shipment</h4>
                                <small>Add a Single shipment or choose to add multiple shipment in bulk through CSV or excel sheet upload</small>
                                <div className="row mt-3">
                                    <div className="col-12 col-md-4">
                                        <div className="d-flex ">
                                            <div className="p-1">
                                                <i className="bx bx-cube"></i>
                                            </div>
                                            <div className="p-1 ">
                                                <a href="/Createshipment">Add Shipment</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <h4>Track Shipment</h4>
                                <div className="row">
                                    <div className="col-12 col-md">
                                        <div className="input-group">
                                            <span className="input-group-text" id="basic-addon1">
                                            <svg className="icon icon-xxs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                            </span>
                                            <input type="text" className="form-control" id="exampleInputIconLeft" placeholder="Search" aria-label="Search" onChange={(e)=>setTrackingnumber(e.target.value)} />
                                            <button className="btn btn-primary text-light" onClick={()=>Trackingshipment()}>Track</button>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    {/* <div className='col-12 col-md-2 col-sm-12 text-end'>
                        {customerDetail.developer_onoff ==='Y' &&
                        <button className="btn btn-gray-800" onClick={()=>handlekey()}>
                            Generate Key
                        </button>
                        }
                    </div> */}
                </div>
            </div>
        </div>
        <section className="mb-3">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="card  border-0 shadow-sm">
                            <h2 className='text-center pt-4'>User Profile</h2>
                            {/* {customerDetail.developer_onoff ==='Y' &&
                            <h6 className='text-center pt-2' style={{"display":"ruby"}}>Developer Key : <h5 >{customerDetail.developer_id}</h5>&nbsp;&nbsp;
                            <CopyToClipboard text={customerDetail.developer_id} onCopy={()=>{toast.success(customerDetail.developer_id+' Copied To Clipboard')}}>
                            <i className="bx bx-copy"></i>
                            </CopyToClipboard></h6>
                            } */}
                        <div className="card-body">
                            <div className="card-body">
                                <div className="col-12 col-md-12">
                                    <div className="row text-center">
                                        <div className="col-12 col-md-6">
                                            <div className='row'>
                                                <div className="col-12 col-md-4">
                                                    <h6>Full Name</h6>
                                                </div>
                                                <div className="col-12 col-md-8">
                                                    {customerDetail.name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className='row'>
                                                <div className="col-12 col-md-4">
                                                    <h6>Company Name</h6>
                                                </div>
                                                <div className="col-12 col-md-8">
                                                    {customerDetail.company}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className='row'>
                                                <div className="col-12 col-md-4">
                                                    <h6>Email</h6>
                                                </div>
                                                <div className="col-12 col-md-8">
                                                    {customerDetail.email}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className='row'>
                                                <div className="col-12 col-md-4">
                                                    <h6>Mobile</h6>
                                                </div>
                                                <div className="col-12 col-md-8">
                                                    {customerDetail.phone}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className='row'>
                                                <div className="col-12 col-md-4">
                                                    <h6>Address</h6>
                                                </div>
                                                <div className="col-12 col-md-8">
                                                    {customerDetail.address}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className='row'>
                                                <div className="col-12 col-md-4">
                                                    <h6>City</h6>
                                                </div>
                                                <div className="col-12 col-md-8">
                                                    {customerDetail.city}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className='row'>
                                                <div className="col-12 col-md-4">
                                                    <h6>Country</h6>
                                                </div>
                                                <div className="col-12 col-md-8">
                                                    {customerDetail.country}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className='row'>
                                                <div className="col-12 col-md-4">
                                                    <h6>Postal/Zip code</h6>
                                                </div>
                                                <div className="col-12 col-md-8">
                                                    {customerDetail.pincode}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
        </section>
            
            <div className="row dash_state_box">
                <div className="col-12 col-sm-6 col-xl-4 mb-4">
                    <div className="card border-0 shadow">
                        <div className="card-body">
                            <div className="row d-block d-xl-flex align-items-center">
                                <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                    <div className="icon-shape icon-shape-default rounded me-4 me-sm-0">
                                        <i className="bx bx-package"></i>
                                    </div>
                                    <div className="d-sm-none">
                                        <h2 className="fw-extrabold h5">Shipments</h2>
                                        <h3 className="mb-1">{totalshipment}</h3>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-7 px-xl-0">
                                    <div className="d-none d-sm-block">
                                        <h2 className=""> Shipments</h2>
                                        <h3 className="">{totalshipment}</h3>
                                    </div>
                                    <small className="">
                                        Last 30 days
                                    </small> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-xl-4 mb-4">
                    <div className="card border-0 shadow">
                        <div className="card-body">
                            <div className="row d-block d-xl-flex align-items-center">
                                <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                    <div className="icon-shape icon-shape-default rounded me-4 me-sm-0">
                                        <i className="bx bx-time"></i>
                                    </div>
                                    <div className="d-sm-none">
                                        <h2 className="fw-extrabold h5">Schedule pickups</h2>
                                        <h3 className="mb-1">0</h3>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-7 px-xl-0">
                                    <div className="d-none d-sm-block">
                                        <h2 className="">Schedule pickups</h2>
                                        <h3 className="">0</h3>
                                    </div>
                                    <small className="">
                                        Last 30 days
                                    </small> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-xl-4 mb-4">
                    <div className="card border-0 shadow">
                        <div className="card-body">
                            <div className="row d-block d-xl-flex align-items-center">
                                <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                    <div className="icon-shape icon-shape-default rounded me-4 me-sm-0">
                                        <i className="bx bx-cube-alt"></i>
                                    </div>
                                    <div className="d-sm-none">
                                        <h2 className="fw-extrabold h5">Delivered</h2>
                                        <h3 className="mb-1">{totalDeliveredshipment}</h3>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-7 px-xl-0">
                                    <div className="d-none d-sm-block">
                                        <h2 className="">Delivered</h2>
                                        <h3 className="">{totalDeliveredshipment}</h3>
                                    </div>
                                    <small className="">
                                        Last 30 days
                                    </small> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="mb-3">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <a href="/Shipmentlist/All" target="_blank" rel="noopener noreferrer">
                        <div className="shipment_card_st shadow-sm">
                            <h1 className='text-center'><i className="bx bx-package"></i></h1>
                            <h4 className='text-center'>My Booking List</h4>
                        </div>
                        </a>
                    </div>
                    <div className="col-12 col-md-4">
                        <a href="/UpdateProfile" target="_blank" rel="noopener noreferrer">
                        <div className="shipment_card_st shadow-sm">
                            <h1 className='text-center'><i className="bx bx-edit-alt"></i></h1>
                            <h4 className='text-center'>Edit Profile</h4>
                        </div>
                        </a>
                    </div>
                    <div className="col-12 col-md-4">
                        <a href="/UpdatePassword" target="_blank" rel="noopener noreferrer">
                        <div className="shipment_card_st shadow-sm">
                            <h1 className='text-center'><i className="bx bx-edit-alt"></i></h1>
                            <h4 className='text-center'>Change Password</h4>
                        </div>
                        </a>
                    </div>
                </div>
            </section>
            </div>
            <Footer/>
            </main>
    </>
  );
}

export default CustomerDashboard;