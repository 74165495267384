import React, {useState, useEffect} from "react";
import Header from "./include/header";
import Footer from "./include/footer";
import LeftPanel from "./include/LeftPanel";
import Global from "../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../AuthUser";
import { useNavigate,useParams } from 'react-router-dom';


function UpdatePassword(){
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const [Password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [Loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    

    useEffect(()=>{
    },[]);
    const items = JSON.parse(localStorage.getItem('user'));
    const handlePassSubmit=()=>{
        if(Password==""){
            toast.warning('Password is Required');
        }
        else if(confirmPassword==""){
            toast.warning('Confirm Password is Required');
        }
        else if(Password!=confirmPassword){
            toast.warning('Password And Confirm Password Must Be Same');
        }
        else{
            setBtnLoading(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'ChangeCustomerPassword',
                headers:headers1,
                data: {
                    UniqueId:items.uniqueid,
                    Password:Password
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    toast.success(response.data.message);
                    setTimeout(() => {
                        navigate('/Customer-Dash');
                      }, 1000);
                }else{
                    toast.warning(response.data.message);
                }
                setBtnLoading(false);
              })
              .catch(function (error) {
                setLoading(false);
                setBtnLoading(false);
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
              });
        }
    }
    
    return(
        <>
            <LeftPanel/>
            <main className='home-section'>
                <Header/>
                <ToastContainer/>
                <section className="left_sub_menu pt-2">
                    <div className="row">
                        <div className="col-12 col-md">
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                                <div className="d-block mb-4 mb-md-0">
                                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                            <li className="breadcrumb-item">
                                                <a href="/Admin-Dash">
                                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                                </a>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">Update Password</li>
                                        </ol>
                                    </nav>
                                    <div className="d-flex justify-content-between">
                                        <div className="">
                                            <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                                            <i className="bx bx-arrow-back "></i>
                                            </button>
                                        </div>
                                        <div className="ps-2 ">
                                            <h2 className="h4">Update Password</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <h6>Change Password</h6>
                                    <div className="row">
                                        <div className="col-12 mb-3 mt-4">
                                            <div className="row">
                                                <div className="col-4 mb-3">
                                                    <label for="" className="form-label">Password: <span className="text-danger">*</span></label>
                                                    <input type="password" className="form-control" onChange={(e)=>setPassword(e.target.value)} placeholder="Please Enter Password" />
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <label for="" className="form-label">Confirm Password: <span className="text-danger">*</span></label>
                                                    <input type="password" className="form-control" onChange={(e)=>setConfirmPassword(e.target.value)} placeholder="Please Enter Confirm Password" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {btnLoading==false?
                                    <div className="d-grid col-md-2 mt-2">
                                        <button className="btn btn-primary" onClick={()=>handlePassSubmit()}>Submit</button>
                                    </div>
                                    :
                                    <div className="d-grid col-md-2 mt-2">
                                        <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer/>
        </main>
    </>
)
}
export default UpdatePassword;