import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import AuthUser from './AuthUser';
import Login from './Customer/Login/login';
import Register from './Customer/Login/Register';
import Otp from './Customer/Login/Otp';
import CustomerDashboard from './Customer/CustomerDashboard';
import Shipmentlist from './Customer/Shipments/Shipmentlist';
import CustomerEdit from './Customer/CustomerEdit';
import UpdatePassword from './Customer/UpdatePassword';
import CustomerWallet from './Customer/CustomerWallet';
import CustomerCredit from './Customer/CustomerCredit';
import ConsignmentNote from './Customer/Shipments/ConsignmentNote';
import ShipmentDetails from './Customer/Shipments/ShipmentDetails';
import ShipmentInvoice from './Customer/Shipments/ShipmentInvoice';
import ShipmentLabel from './Customer/Shipments/ShipmentLabel';
import ShipmentTracking from './Customer/Shipments/ShipmentTracking';
import ShipmentPrintReceipt from './Customer/Shipments/ShipmentPrintReceipt';
import WarehouseAdd from './Customer/WarehouseAdd';
import WarehouseList from './Customer/WarehouseList';
import WarehouseEdit from './Customer/WarehouseEdit';
import Developerkey from './Customer/Developerkey';

function App() {
  const {getToken}=AuthUser();
  if(!getToken()){
    return(
      <CookiesProvider>
        <Router>
              <Routes>
                <Route exact path='/' element={<Login/>} />
                <Route exact path='/Register' element={<Register/>} />
                <Route exact path='/Otp' element={<Otp/>} />
              </Routes>
            </Router>
      </CookiesProvider>
    )
  }else{
    return (
      <CookiesProvider>
        <Router>
              <Routes>
                <Route exact path='/' element={<Login/>} />
                <Route exact path='/Register' element={<Register/>} />
                <Route exact path='/Otp' element={<Otp/>} />
                <Route exact path='/Customer-Dash' element={<CustomerDashboard />} />
                <Route exact path='/Shipmentlist/:type' element={<Shipmentlist />} />
                <Route exact path='/UpdateProfile' element={<CustomerEdit />} />
                <Route exact path='/UpdatePassword' element={<UpdatePassword />} />
                <Route exact path='/CustomerWallet' element={<CustomerWallet />} />
                <Route exact path='/CustomerCredit' element={<CustomerCredit />} />
                <Route exact path='/ConsignmentNote/:slipno' element={<ConsignmentNote />} />
                <Route exact path='/ShipmentDetails/:slip_no' element={<ShipmentDetails />} />
                <Route exact path='/ShipmentInvoice/:slip_no' element={<ShipmentInvoice />} />
                <Route exact path='/ShipmentLabel' element={<ShipmentLabel />} />
                <Route exact path='/ShipmentTracking/:slip_no' element={<ShipmentTracking />} />
                <Route exact path='/ShipmentPrintReceipt/:slip_no' element={<ShipmentPrintReceipt />} />
                <Route exact path='/WarehouseAdd' element={<WarehouseAdd />} />
                <Route exact path='/WarehouseList' element={<WarehouseList />} />
                <Route exact path='/WarehouseEdit/:uniqueid' element={<WarehouseEdit />} />
                <Route exact path='/Developerkey' element={<Developerkey />} />
              </Routes>
            </Router>
      </CookiesProvider>
    );
  }
}

export default App;
