import { useState, useEffect } from 'react';
import Header from './include/header';
import Footer from './include/footer';
import LeftPanel from './include/LeftPanel';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Global from '../APIConfig';
import  axios from "axios";
import AuthUser from "../AuthUser";
import CopyToClipboard from 'react-copy-to-clipboard';

function Developerkey() {
    let navigate = useNavigate();
    const{headers1,logout}=AuthUser();
    const [customerDetail, setCustomerDetail] = useState('');
const items = JSON.parse(localStorage.getItem('user'));
    useEffect(() => {
        GetCustomerDetail();
        }, []);
        const GetCustomerDetail = () =>{
            axios({
                method: 'post',
                url: Global.baseUrl+'getFrontCutomerDetail',
                headers:headers1,
                data: {
                    UniqueId:items.uniqueid
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    var data=response.data;
                    setCustomerDetail(data.value);
                    
                }
                else
                {
                    setCustomerDetail('');
                    
                }
              })
              .catch(function (error) {
                if(error.response.status==401){
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
          }
        const handlekey = ()=>{
            const userConfirmed = window.confirm("Do you want to proceed?");
            if(userConfirmed ==true)
            {
                axios({
                    method: 'post',
                    url: Global.baseUrl+'generateKey',
                    headers:headers1,
                    data: {
                        UniqueId:items.uniqueid
                    }
                    })
                    .then(function (response) {
                    if(response.data.status == 200)
                    {
                        window.location.reload();
                    }
                    else
                    {
                        toast.warning(response.data.message);
                    }
                    })
                    .catch(function (error) {
                    if(error.response.status==401){
                        logout();
                        navigate('/');
                        window.location.reload();
                    }
                    // console.log(error);
                    });
            }
            else
            {
                
            }
        }
      
  return (
    <>
        <LeftPanel/>
        <main className='home-section'>
        <Header/>
        <ToastContainer/>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Developer APIs</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">Developer APIs</h2>
                        </div>
                    </div>
                </div>
            </div>
        <h6>You will need Access Key to access the API. Please Find Out the details below. Keep the details secret and private </h6>
        <section className="mb-3 pt-3">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="card p-3 border-0 shadow-sm">
                            <div className="col-12 col-md-12">
                                <div className='row'>
                                    <div className='col-12 col-md-8'>
                                        <h2 className=''>Access Key</h2> 
                                    </div>
                                    <div className='col-12 col-md-4'>
                                    {customerDetail.developer_onoff ==='Y' &&
                                    <h3><span className="badge badge-pill badge-dark1 cursors p-2" onClick={()=>handlekey()}>
                                        Generate Key
                                    </span></h3>
                                    } 
                                    </div>
                                </div>
                                <h6 className='text-center pt-2 pb-3' style={{"display":"ruby"}}>Your Access Developer Key is:</h6><br />
                                {customerDetail.developer_onoff ==='Y' ?
                                <>
                                <small className='pt-4 text-danger'>{customerDetail.developer_id}</small> &nbsp;&nbsp;&nbsp;&nbsp;
                                <CopyToClipboard text={customerDetail.developer_id} onCopy={()=>{toast.success(customerDetail.developer_id+' Copied To Clipboard')}}>
                                <span class="badge badge-pill badge-primary1 cursors">Copy</span>
                                </CopyToClipboard>
                                </>
                                :
                                <h6 className='p-2'><small>Click to Generate Button to Create Key</small></h6>
                                }
                            </div>
                        </div>
                    </div>
                </div>
        </section>
            
            <Footer/>
            </main>
    </>
  );
}

export default Developerkey;