import React, { useState, useEffect } from "react";
import axios from "axios";
import Global from "../../APIConfig";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import AuthUser from "../../AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Barcode from "../../Barcode";

function ShipmentInvoice() {
    let navigate = useNavigate();
    const { slip_no } =useParams();
    const [shipmentdata, setShipmentdata] = useState([]);
    const { headers1, headers2, logout } = AuthUser();
    useEffect(() => {
        GetInvoice();
    }, []);
    const GetInvoice = () => {
        axios({
            method: 'post',
            url: Global.baseUrl + 'CustomerShipmentInvoice',
            headers: headers1,
            data: {
                slip_no: slip_no
            }
        })
            .then(function (response) {
                if (response.data.status == 200) {
                    var data = response.data.value;
                    setShipmentdata(data);
                }
            })
            .catch(function (error) {
                if (error.response.status == 401) {
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
            });
    }

    return (
        <div className="container mt-3 wrap">
            <div className="card mb-1">
                <div className="card-body">
                    <table className="py-5"  width="100%">
                        <tr>
                        <td  width="33%">
                            <img src={`${Global.logoUrl}${shipmentdata.Defaultlogo}`} />
                        </td>
                        
                        <td>
                            <h2 className="text-center">{shipmentdata.systemcompanyname}</h2>
                        </td>
                        <td width="33%" className="text-end"><Barcode value={shipmentdata.slip_no} />
                        </td>
                        </tr>
                    </table>
                    <div className="border border-dark border-3 p-3 mb-3 text-center">
                        <h3>INVOICE</h3>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="pb-4">
                                <h6>SENDER DETAILS</h6>
                                <h6 className="smallinvoice">{shipmentdata.sender_name}</h6>
                                <h6 className="smallinvoice">{shipmentdata.sender_zip}</h6>
                                <h6 className="smallinvoice">{shipmentdata.sender_address}</h6>
                                <h6 className="smallinvoice">{shipmentdata.sender_email}</h6>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="pb-4">
                            <h6>RECEIVER DETAILS</h6>
                                <h6 className="smallinvoice">{shipmentdata.reciever_name}</h6>
                                <h6 className="smallinvoice">{shipmentdata.reciever_zip}</h6>
                                <h6 className="smallinvoice">{shipmentdata.reciever_address}</h6>
                                <h6 className="smallinvoice">{shipmentdata.reciever_email}</h6>
                                
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="pb-4 invoice_detail">
                            <h6>INVOICE DETAILS:</h6>
                            <table className="table">
                                <tbody>
                                    <tr>
                                    <td><strong>INVOICE NO. </strong></td>
                                    <td>: {shipmentdata.invoice_no}</td>
                                    </tr>
                                    <tr>
                                    <td><strong>INVOICE DATE </strong></td>
                                    <td>: {shipmentdata.entrydate}</td>
                                    </tr>
                                    
                                    
                                    <tr>
                                    <td><strong>AWB NO.</strong></td>
                                    <td>:  {shipmentdata.master_slip_no}</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="card mb-1">
                <div className="card-body ">
                <div className="">
                    <table className="table table-striped">
                        <thead>
                            <tr className="">
                            <th>S.NO. </th>
                            <th>PRODUCT NAME </th> 
                            <th>QTY </th>
                            <th>CHARGABLE WEIGHT </th>
                            
                            <th>TOTAL </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>1</td>
                            <td><strong>{shipmentdata.status_describtion}</strong></td> 
                            <td>{shipmentdata.pieces}</td>
                            <td>{shipmentdata.volumetric_weight} {shipmentdata.weightType}</td> 
                            <td></td>
                            </tr>
                            <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            </tr>
                            <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Shipping</td>
                            <td>{shipmentdata.service_charge} {shipmentdata.gDefaultCurrency}</td>
                            </tr>
                            {shipmentdata.fuel_surcharge != '0' &&
                            <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Fuel Surcharge</td>
                            <td>{shipmentdata.fuel_surcharge} {shipmentdata.gDefaultCurrency}</td>
                            </tr>
                            }
                            {shipmentdata.insurence_charge != '0' &&
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Insurance Charge</td>
                                <td>{shipmentdata.insurence_charge} {shipmentdata.gDefaultCurrency}</td>
                            </tr>
                            }
                            {shipmentdata.cod_surcharge != '0' &&
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>COD Charge</td>
                                <td>{shipmentdata.cod_surcharge} {shipmentdata.gDefaultCurrency}</td>
                            </tr>
                            }
                            {shipmentdata.ESS_charges != '0' &&
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Temporary Emergency Situation Surcharge (ESS)</td>
                                <td>{shipmentdata.ESS_charges} {shipmentdata.gDefaultCurrency}</td>
                            </tr>
                            }
                            {shipmentdata.elevated_risk_charges != '0' &&
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Elevated Risk Charge</td>
                                <td>{shipmentdata.elevated_risk_charges} {shipmentdata.gDefaultCurrency}</td>
                            </tr>
                            }
                            {shipmentdata.resticted_destination_charges != '0' &&
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Resticted Destination Charges</td>
                                <td>{shipmentdata.resticted_destination_charges} {shipmentdata.gDefaultCurrency}</td>
                            </tr>
                            }
                            {shipmentdata.remote_area_delivery_charges != '0' &&
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Remote Area Delivery Charges</td>
                                <td>{shipmentdata.remote_area_delivery_charges} {shipmentdata.gDefaultCurrency}</td>
                            </tr>
                            }
                            {shipmentdata.custom_surcharge != '0' &&
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Custom Clearance Charge</td>
                                <td>{shipmentdata.custom_surcharge} {shipmentdata.gDefaultCurrency}</td>
                            </tr>
                            }
                            <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{shipmentdata.taxtype} ({shipmentdata.defaultservicetax}%)</td>
                            <td>{shipmentdata.service_tax} {shipmentdata.gDefaultCurrency}</td>
                            </tr>
                            <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>VAT ({shipmentdata.defaultvat}%)</td>
                            <td>{shipmentdata.vat} {shipmentdata.gDefaultCurrency}</td>
                            </tr>
                            <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Grand Total (include)</td>
                            <td>{shipmentdata.total_amt} {shipmentdata.gDefaultCurrency}</td>
                            </tr>
                        </tbody>
                    </table>

                    <hr />
                    <div className="row">
                        <div className="col-8">
                            <div className="sign_box">

                            </div>
                            
                            <strong>Authorized Signature for CompanyName</strong>
                        </div>
                        <div className="col-4">
                        
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
      );       
}
export default ShipmentInvoice;