import React, { useState, useEffect } from "react";
import Header from "./include/header";
import Footer from "./include/footer";
import UserLeftPanel from "./include/LeftPanel";
import axios from "axios";
import Global from "../APIConfig";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams,Link } from "react-router-dom";
import MainLoader from "./Loaders/MainLoader";
import AuthUser from "../AuthUser";
import CopyToClipboard from 'react-copy-to-clipboard';


function WarehouseList(){
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [list, setList] = useState([]);

    useEffect(()=>{
        getWarehouseList();
    },[]);
    const items = JSON.parse(localStorage.getItem('user'));
    const getWarehouseList=()=>{
        setLoading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'WarehouseList',
            headers:headers1,
            data: {
                cust_id:items.uniqueid,
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setList(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
            setLoading(false);
          });
    }
    const handleservicestatus=(id,val)=>{
        if (window.confirm('Do you want to proceed?')) {
        axios({
            method: 'post',
            url: Global.baseUrl+'warehouseStatusupdate',
            headers:headers1,
            data: {
                id:id,
                status: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                getWarehouseList();
                toast.success(response.data.message);
            }
            else
            {
                toast.warn(response.data.message);
            }
          })   
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
        }
        else
        {
            getWarehouseList();
        }
    }
    const handledeletelocation=(id)=>{
        if (window.confirm('Do You  want to delete ?')) {
        axios({
            method: 'post',
            url: Global.baseUrl+'WarehouseDelete',
            headers:headers1,
            data: {
                id: id
            }
        })
        .then(function (response) {
            if(response.data.status == 200)
            {
                toast.success(response.data.message);
            }
            else
            {
                toast.warn(response.data.message);
            }
            getWarehouseList('');
        })
        .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
        }
        else
        {
            getWarehouseList('');
        }
    }
    return(
        <>
        <UserLeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true? <MainLoader/>
            :
            <section className="my-3">
                <div className="row">
                    <div className="col-12 col-md">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div className="d-block mb-4 mb-md-0">
                                <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                                    <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                        <li className="breadcrumb-item">
                                            <a href="/Admin-Dash">
                                            <i className="bx bx-home text-primary-400 fs-6"></i>
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">Warehouse List</li>
                                    </ol>
                                </nav>
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                                        <i className="bx bx-arrow-back "></i>
                                        </button>
                                    </div>
                                    <div className="ps-2 ">
                                        <h2 className="h4">Warehouse List</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-toolbar mb-2 mb-md-0">
                            </div>
                        </div>
                    </div>
                </div>
                    <div className="col-12 col-md-12 customer_box_ei">
                        <div className="col-12 col-md-12">
                            <section className="data_table">
                                <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>					
                                                <th className="border-gray-200">#</th>
                                                <th className="border-gray-200">Warehouse ID</th>
                                                <th className="border-gray-200">Warehouse Name</th>
                                                <th className="border-gray-200">Phone No.</th>
                                                <th className="border-gray-200">Address</th>
                                                <th className="border-gray-200">Active</th>
                                                <th className="border-gray-200">Action</th>
                                            </tr>
                                        </thead>
                                        {list.length > 0 ?
                                        <tbody>
                                            {list.map((ware,index)=>{
                                                return(
                                                <tr key={index}>
                                                    <td>
                                                        {index+1}
                                                    </td>
                                                    <td><span className="fw-normal text-capitalize"><a>{ware.unique_id}</a>
                                                         <CopyToClipboard text={ware.unique_id} onCopy={()=>{toast.success(ware.unique_id+' Copied To Clipboard')}}>
                                                        <i className="bx bx-copy"></i>
                                                        </CopyToClipboard></span>
                                                    </td>
                                                    <td><span className="fw-normal text-capitalize">{ware.warehouseName}</span></td>
                                                    <td>
                                                        <span className="fw-normal text-capitalize">{ware.phone}</span>
                                                    </td>
                                                    <td><span className="fw-normal text-capitalize">{ware.address} ,{ware.city} ,{ware.country}</span></td>
                                                    <td>
                                                        <div class="form-check form-switch  mb-2">
                                                        <input class="form-check-input" type="checkbox" role="switch" checked={ware.active=='Y'? true : false} onChange={()=>{ware.active=='Y'? handleservicestatus(ware.id,'N'):handleservicestatus(ware.id,'Y')}} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Link to={`/WarehouseEdit/${ware.unique_id}`} class="btn btn-gray-100">Edit Warehouse</Link>
                                                        <button class="btn btn-gray-100" onClick={()=>handledeletelocation(ware.id)}>Delete Warehouse</button>
                                                    </td>
                                                </tr> 
                                                );
                                            })}                  
                                        </tbody>
                                        :
                                        <tbody>
                                            <tr className="text-center text-danger"><td colSpan={6}>Record Not Found</td></tr>
                                        </tbody>
                                        }
                                    </table>
                                    
                                </div>
                            </section>
                        </div>
                    </div>
            </section>
            }
            <Footer/>
        </main>
        </>

    );
}
export default WarehouseList;