import React from "react";
import { useNavigate, Link } from "react-router-dom";

function Footer(){
    let navigate = useNavigate();
    const items = JSON.parse(localStorage.getItem('user'));

    return(
        <>
<div className="offcanvas offcanvas-end" id="mega_menu">
  <div className="offcanvas-header">
    <h3 className="offcanvas-title">Menu</h3>
    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
  </div>
  <div className="offcanvas-body mega_menu_canvas">
    
  
  <div className="card">
    <div className="card-body">
    <h5>Operational</h5>
  <ul className="list-group color-list">
    <li className="list-group-item d-flex justify-content-between align-items-center">
      <div><i className="bx bx-copy"></i> <a href="/Shipmentlist/All">Shipments Management</a></div>
      <span className=""><i className="bx bx-chevron-right"></i></span>
    </li>
    <li className="list-group-item d-flex justify-content-between align-items-center">
      <div><i className="bx bx-copy"></i><a href="/PickupList/Running">PRS Running Management</a></div>
      <span className=""><i className="bx bx-chevron-right"></i></span>
    </li>
    <li className="list-group-item d-flex justify-content-between align-items-center ">
      <div><i className="bx bx-copy"></i><a href="/BagList/Running"> Bag Management</a></div>
      <span className=""><i className="bx bx-chevron-right"></i></span>
    </li>
    <li className="list-group-item d-flex justify-content-between align-items-center">
      <div><i className="bx bx-copy"></i><a href="/BagManifestList/Running">Bag Manifest Management</a></div>
      <span className=""><i className="bx bx-chevron-right"></i></span>
    </li>
    <li className="list-group-item d-flex justify-content-between align-items-center">
      <div><i className="bx bx-copy"></i><a href="/ShipmentManifestList/Running">Shipment Manifest Management</a></div>
      <span className=""><i className="bx bx-chevron-right"></i></span>
    </li>
    <li className="list-group-item d-flex justify-content-between align-items-center">
      <div><i className="bx bx-copy"></i><a href="/DrsList/Running"> DRS Management</a></div>
      <span className=""><i className="bx bx-chevron-right"></i></span>
    </li>
    <li className="list-group-item d-flex justify-content-between align-items-center">
      <div><i className="bx bx-copy"></i><a href="/WarehouseScanner/Pickup"> Warehouse Inbound Scanner</a></div>
      <span className=""><i className="bx bx-chevron-right"></i></span>
    </li>
  </ul>
    </div>
  </div>

  </div>
</div>
        <div className="theme-settings card bg-gray-800 pt-2 collapse" id="theme-settings">
                <div className="card-body bg-gray-800 text-white pt-4">
                    <button type="button" className="btn-close theme-settings-close" aria-label="Close" data-bs-toggle="collapse"
                        href="#theme-settings" role="button" aria-expanded="false" aria-controls="theme-settings"></button>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <p className="m-0 mb-1 me-4 fs-7">Open source <span role="img" aria-label="gratitude">💛</span></p>
                        
                    </div>
                    
                    <p className="fs-7 text-gray-300 text-center">Available in the following technologies:</p>
                    
                </div>
            </div>

            <div className="card theme-settings bg-gray-800 theme-settings-expand show" id="theme-settings-expand">
                <div className="card-body bg-gray-800 text-white rounded-top p-3 py-2">
                    <span className="fw-bold d-inline-flex align-items-center h6">
                        <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd"></path></svg>
                        Settings
                    </span>
                </div>
            </div>
            </>
    );
}
export default Footer;