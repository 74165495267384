import React, { useState, useEffect } from "react";
import Header from "./include/header";
import Footer from "./include/footer";
import UserLeftPanel from "./include/LeftPanel";
import axios from "axios";
import Global from "../APIConfig";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams,Link } from "react-router-dom";
import MainLoader from "./Loaders/MainLoader";
import AuthUser from "../AuthUser";


function CustomerWallet(){
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [TotalCr, setTotalCr] = useState('');
    const [TotalDr, setTotalDr] = useState('');
    const [WalletHistory, setWalletHistory] = useState([]);
    const [gDefaultCurrency, setGDefaultCurrency] = useState('$');

    useEffect(()=>{
        getCutomerWalletDetail();
    },[]);
    const items = JSON.parse(localStorage.getItem('user'));
    const getCutomerWalletDetail=()=>{
        setLoading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'getfrontCutomerWalletDetail',
            headers:headers1,
            data: {
                UniqueId:items.uniqueid,
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setTotalCr(data.totalcr);
                setTotalDr(data.totaldr);
                setWalletHistory(data.history);
                setGDefaultCurrency(data.gDefaultCurrency)
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
            setLoading(false);
          });
    }
    
    return(
        <>
        <UserLeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true? <MainLoader/>
            :
            <section className="my-3">
                <div className="row">
                    <div className="col-12 col-md">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div className="d-block mb-4 mb-md-0">
                                <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                                    <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                        <li className="breadcrumb-item">
                                            <a href="/Admin-Dash">
                                            <i className="bx bx-home text-primary-400 fs-6"></i>
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">Wallet History</li>
                                    </ol>
                                </nav>
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                                        <i className="bx bx-arrow-back "></i>
                                        </button>
                                    </div>
                                    <div className="ps-2 ">
                                        <h2 className="h4">Wallet History</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-toolbar mb-2 mb-md-0">
                            </div>
                        </div>
                    </div>
                </div>
                    <div className="col-12 col-md-12 customer_box_ei">
                        <div className="customer_top_boxx mb-3">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <h2><i className="bx bx-wallet"></i> Wallet Balance</h2>
                                            <h3><small>{gDefaultCurrency} </small>{parseFloat(TotalCr).toFixed(2)}</h3>
                                            <h6><small>Used Balance:</small> <small>{gDefaultCurrency} </small>{parseFloat(TotalDr).toFixed(2)}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="row">
                                    <div className="col-12 col-md-6 mb-3">
                                        <div className="card border-1 shadow ">
                                            <div className="card-body">
                                                <div className="row d-block d-xl-flex align-items-center">
                                                    <div className="col-12 px-xl-0">
                                                        <div className="d-none d-sm-block">
                                                            <h6 className="text-gray-500 mb-0"> Total Credit</h6>
                                                            <h3 className="fw-extrabold mb-0 mt-2"><small>{gDefaultCurrency}</small>{parseFloat(TotalCr).toFixed(2)}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <div className="card border-1 shadow ">
                                            <div className="card-body">
                                                <div className="row d-block d-xl-flex align-items-center">
                                                    <div className="col-12 px-xl-0">
                                                        <div className="d-none d-sm-block">
                                                        <h6 className="text-gray-500 mb-0"> Total Debit</h6>
                                                            <h3 className="fw-extrabold mb-0 mt-2"><small>{gDefaultCurrency}</small>{parseFloat(TotalDr).toFixed(2)}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <section className="data_table">
                                    <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>					
                                                    <th className="border-gray-200">#</th>
                                                    <th className="border-gray-200">Entry Date</th>
                                                    <th className="border-gray-200">Transaction Id</th>
                                                    <th className="border-gray-200">Amount</th>
                                                    <th className="border-gray-200">Payment Mode</th>
                                                    <th className="border-gray-200">Payment Detail</th>
                                                </tr>
                                            </thead>
                                            {WalletHistory.length > 0 ?
                                            <tbody>
                                                {WalletHistory.map((history,index)=>{
                                                    return(
                                                    <tr key={index}>
                                                        <td>
                                                            {index+1}
                                                        </td>
                                                        <td><span className="fw-normal text-capitalize">{history.date}</span></td>
                                                        <td><span className="fw-normal text-capitalize">{history.transaction_id}</span></td>
                                                        <td>
                                                            {history.Dr>0?
                                                            <span className="fw-normal text-capitalize text-danger">-{gDefaultCurrency}{history.Dr}</span>
                                                            :
                                                            <span className="fw-normal text-capitalize text-success">+{gDefaultCurrency}{history.Cr}</span>
                                                            }
                                                        </td>
                                                        <td><span className="fw-normal text-capitalize">{history.mode}</span></td>
                                                        <td><span className="fw-normal text-capitalize">{history.detail_type}</span></td>
                                                    </tr> 
                                                    );
                                                })}                  
                                            </tbody>
                                            :
                                            <tbody>
                                                <tr className="text-center text-danger"><td colSpan={6}>Record Not Found</td></tr>
                                            </tbody>
                                            }
                                        </table>
                                        
                                    </div>
                                </section>
                        </div>
                    </div>
            </section>
            }
            <Footer/>
        </main>
        </>

    );
}
export default CustomerWallet;